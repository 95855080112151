<!--
 * @Descripttion: 确定结果
 * @version: 1.0
 * @Author: Niklaus
 * @Date: 2021-08-28 14:33:24
-->
<template>
  <el-container>
    <el-main>
      <el-header>
        <h1>确定竞价结果</h1>
      </el-header>
      <el-table  :data="items" header-cell-class-name="header-style" :style="project.quotationMethod=='1'?'width:1040px' : 'width:100%'">
        <el-table-column prop="itemName" label="标的名称" width="300px"></el-table-column>
        <el-table-column label='规格型号'  min-width='350px'  prop='standards' ></el-table-column>
        <el-table-column label='采购数量'  width='100px'  prop='purchaseQuantity' ></el-table-column>
        <el-table-column label='计量单位'  width='100px'  prop='uom' ></el-table-column>
        <!-- <el-table-column :label="project.quotationMethod =='1' ? '预算总金额（元）' : '预算优惠率（%）'"  width='150px'  prop='itemBudget' align="right" ></el-table-column> -->
        <template v-if="project.quotationMethod == '1'">
        <el-table-column label="预算总金额（元）"  width='150px'  prop='itemBudget' align="right" ></el-table-column>
        </template>
        <template v-else-if="project.quotationMethod == '2'">
        <el-table-column label="预算优惠率（%）"  width='150px'  prop='itemBudget' align="right" ></el-table-column>
        <el-table-column label='预算暂定价（元）'  width= '150px'  prop='budgetTempPrice'></el-table-column>
        <!-- <el-table-column label='人工费（元）'  width= '150px'  prop='laborCost'></el-table-column> -->
        <!-- <el-table-column label='安全文明施工费（元）' width= '250px'  prop='safeCivilizeCost'></el-table-column> -->
        </template>
        <template v-else-if="project.quotationMethod == '3'">
        <el-table-column label="预算控制价（元）"  width='150px'  prop='itemBudget' align="right" ></el-table-column>
        <el-table-column label='最高限价（元）'  width= '150px'  prop='ceilingPrice'></el-table-column>
        <!-- <el-table-column label='人工费（元）'  width= '150px'  prop='laborCost'></el-table-column> -->
        <!-- <el-table-column label='安全文明施工费（元）' width= '250px'  prop='safeCivilizeCost'></el-table-column> -->
        </template>
        <template v-else-if="project.quotationMethod == '4'">
        <el-table-column label="预算金额（元）"  width='150px'  prop='itemBudget' align="right" ></el-table-column>
        <el-table-column label='最低加价幅度（元）'  width= '250px'  prop='miniPriceIncreRange'></el-table-column>
        </template>

      </el-table>
      <div class="sum-row" v-if="items.length > 1">
        <p>合计:</p>
        <p style="text-align: right;">{{ totalItemBugdet }}</p>
      </div>
      <p class="mid-title mt">供应商竞价</p>
      <el-table :data="supplierList"  header-cell-class-name="header-style" class="mt">
        <el-table-column label="排名" width="50px" align="center" prop="seqNum"></el-table-column>
        <el-table-column v-if="project.quotationMethod == '4'" label="确认状态" width="100px" align="center" prop="confirmStatus">
          <template slot-scope="scope">
            <template v-if="scope.row.confirmStatus =='00'">
              <el-button type="danger" round size="mini">未确认</el-button>
            </template>
            <template v-else-if="scope.row.confirmStatus =='01'">
              <el-button type="success" round size="mini">已确认</el-button>
            </template>
            <template v-else-if="scope.row.confirmStatus =='02'">
              <el-button type="primary" round size="mini">自动确认</el-button>
            </template>
          </template>
        </el-table-column>
        <el-table-column label="供应商名称" width="240px" prop="supplierStoreName"></el-table-column>

        <!-- <el-table-column :label="project.quotationMethod =='1'? '最终总报价（元）':'优惠率报价（%）'" width="140px">
          <template slot-scope="scope">{{ scope.row.price? scope.row.price : '' }}</template>
        </el-table-column> -->
        <!-- <el-table-column :label="project.quotationMethod =='1' ? '报价总金额（元）' : '最终报价（%）'" width="140px" align="right">
          <template slot-scope="scope">{{ scope.row.price? scope.row.price : '' }}</template>
        </el-table-column> -->

        <template v-if="project.quotationMethod == '1' ">
        <el-table-column label="报价总金额（元）" width="140px" align="right">
          <template slot-scope="scope">{{ scope.row.price? scope.row.price : '' }}</template>
        </el-table-column>
        </template>

        <template v-else-if="project.quotationMethod == '2' ">
        <el-table-column label="最终报价（%）" width="140px" align="right">
          <template slot-scope="scope">{{ scope.row.price? scope.row.price : '' }}</template>
        </el-table-column>
        <el-table-column label='报价总金额（元）' width="220px">
          <template slot-scope="scope">{{ scope.row.offerTotalPrice? scope.row.offerTotalPrice : '' }}</template>
        </el-table-column>
        <!-- <el-table-column label="人工费（元）" width="140px">
          <template>{{ items.length > 0 ? items[0].laborCost : '' }} </template>
        </el-table-column> -->
        <!-- <el-table-column label="安全文明施工费（元）" width="220px">
          <template >{{ items.length > 0 ? items[0].safeCivilizeCost : ''}} </template>
        </el-table-column> -->
        </template>

        <template v-else-if="project.quotationMethod == '3' ">
        <el-table-column label="报价总金额（元）" width="140px" align="right">
          <template slot-scope="scope">{{ scope.row.price? scope.row.price : '' }}</template>
        </el-table-column>
        <!-- <el-table-column label="人工费（元）" width="140px">
          <template>{{ items.length > 0 ? items[0].laborCost : '' }} </template>
        </el-table-column> -->
        <!-- <el-table-column label="安全文明施工费（元）" width="220px">
          <template >{{ items.length > 0 ? items[0].safeCivilizeCost : ''}} </template>
        </el-table-column> -->
        </template>
        <template v-else-if="project.quotationMethod == '4' ">
        <el-table-column label="最终总报价（元）" width="200px" align="right">
          <template slot-scope="scope">{{ scope.row.price? scope.row.price : '' }}</template>
        </el-table-column>
        </template>

        <el-table-column label="报价时间" width="160px">
          <template slot-scope="scope">{{ scope.row.offerDatetime? scope.row.offerDatetime : scope.row.updateTime }}</template>
        </el-table-column>
        <el-table-column label="竞价附件" width="100px">
          <template slot-scope="scope">
            <el-button type="mini" size="primary" @click="showFile(scope.row)">查看</el-button>
          </template>
        </el-table-column>
        <el-table-column label="竞价结果" width="150px" align="center">
          <template slot-scope="scope">
            <template v-if="project.processDetailStatus == '12'">
              <el-tag type="info" size="mini">报价中</el-tag>
            </template>
            <template v-else-if="project.processDetailStatus == '13' ">
              <el-tag type="info" size="mini">待确定</el-tag>
            </template>
            <template v-else>
              <el-tag type="success" size="mini" v-if="scope.row.ifDeal == '1'">已成交</el-tag>
              <el-tag type="info" size="mini" v-else>未成交</el-tag>
            </template>
          </template>
        </el-table-column>
        <el-table-column label="原因" prop="remark" width="200px"></el-table-column>
      </el-table>
    </el-main>
    <el-footer class="fix-footer" style="text-align: center;">
      <el-button type="info" @click="$router.push('/participate')">返回</el-button>
      <!-- <el-button type="danger" @click="handleTerminate">终止项目</el-button>
      <el-button type="primary">重新竞价</el-button> -->
    </el-footer>

  <!-- 成交确认 -->
  <el-dialog :visible.sync="confirmDialog" center width="700px">
    <template #title>
      <h1 class="mid-title">成交确认</h1>
    </template>
    <template v-if="project.quotationMethod == '1' ">
    <el-row>
      <el-col class="desc" :span="6" :offset="4">预算总金额（元）：</el-col>
      <el-col class="bw" :span="10">{{  budgetTotal ? budgetTotal: '' }}</el-col>
    </el-row>
    </template>
    <!-- 优惠率报价显示的字段： 优惠率报价项目只有一个条目 -->
    <template v-else-if="project.quotationMethod == '2' ">
    <el-row>
      <el-col class="desc" :span="6" :offset="4">预算暂定价（元）：</el-col>
      <el-col class="bw" :span="10">{{ items.length > 0 ? items[0].budgetTempPrice : '' }}</el-col>
    </el-row>

    <!-- <el-row>
      <el-col class="desc" :span="6" :offset="4">人工费（元）：</el-col>
      <el-col class="bw" :span="10">{{ supplierList[rateProjectItemIndex].laborCost }}</el-col>
    </el-row> -->
    <!-- <el-row>
      <el-col class="desc" :span="6" :offset="4">安全文明施工费（元）：</el-col>
      <el-col class="bw" :span="10">{{ supplierList[rateProjectItemIndex].safeCivilizeCost}}</el-col>
    </el-row> -->
    </template>
    <!-- 金额报价定制版显示的字段： 金额报价定制版项目只有一个条目 -->
    <template v-else-if="project.quotationMethod == '3' ">
    <el-row>
      <el-col class="desc" :span="6" :offset="4">预算控制价（元）：</el-col>
      <el-col class="bw" :span="10">{{  budgetTotal ? budgetTotal: '' }}</el-col>
    </el-row>
    <el-row>
      <el-col class="desc" :span="6" :offset="4">最高限价（元）：</el-col>
      <el-col class="bw" :span="10">{{ items.length > 0 ? items[0].ceilingPrice : '' }}</el-col>
    </el-row>

    <!-- <el-row>
      <el-col class="desc" :span="6" :offset="4">人工费（元）：</el-col>
      <el-col class="bw" :span="10">{{ items.length > 0 ? items[0].laborCost : '' }}</el-col>
    </el-row> -->
    <!-- <el-row>
      <el-col class="desc" :span="6" :offset="4">安全文明施工费（元）：</el-col>
      <el-col class="bw" :span="10">{{ items.length > 0 ? items[0].safeCivilizeCost : '' }}</el-col>
    </el-row> -->
    </template>

    <el-row>
      <el-col class="desc" :span="6" :offset="4" v-if="project.quotationMethod =='1' ">报价总金额（元）</el-col>
      <el-col class="desc" :span="6" :offset="4" v-else-if="project.quotationMethod =='2' ">报价优惠率（%）</el-col>
      <el-col class="desc" :span="6" :offset="4" v-else-if="project.quotationMethod =='3' ">报价总金额（元）</el-col>
      <el-col class="desc" :span="6" :offset="4" v-else-if="project.quotationMethod =='4' ">报价金额（元）</el-col>
      <el-col class="bw" :span="10">{{ tempSupplier.price?tempSupplier.price :'暂未报价' }} </el-col>
    </el-row>

    <el-row v-if="project.quotationMethod == '2'">
      <el-col class="desc" :span="6" :offset="4">报价总金额（元）： </el-col>
      <el-col class="bw" :span="10">{{ tempSupplier.offerTotalPrice?tempSupplier.offerTotalPrice :'暂未报价' }} </el-col>
    </el-row>
    <el-row>
      <el-col class="desc" :span="6" :offset="4">供应商名称：</el-col>
      <el-col class="bw" :span="10">{{ tempSupplier.supplierStoreName }}</el-col>
    </el-row>
    <el-row>
      <el-col class="desc" :span="6" :offset="4">成交方式：</el-col>
      <el-col class="bw" :span="10">
        <el-tag  type="success" size="medium" effect="dark">[ 第 {{ tempSupplier.seqNum }} 名成交 ]</el-tag>
      </el-col>
    </el-row>
    <el-row v-if="tempSupplier.seqNum !='1'" class="mt-normal">
      <el-col class="desc bw" :span="6" :offset="4">成交原因</el-col>
      <el-col class="bw" :span="10">
        <el-input type="textarea" v-model="resultReason" :autosize="{minRows: 4}" placeholder="请输入原因（必填）"></el-input>
      </el-col>
    </el-row>

    <span slot="footer">
      <el-button type="info" @click="confirmDialog = false">取消</el-button>
      <el-button type="primary" @click="handleComfirm">确定成交</el-button>
    </span>
  </el-dialog>

  <el-dialog
    :title="tempSupplier.supplierStoreName"
    :visible.sync="fileDialg"
    width ="60%"
    center>
    <el-main>
      <el-table :data="tempFiles" border stripe>
        <el-table-column v-for="col in fileColumns"
          :prop="col.prop"
          :key="col.id"
          :label="col.label"
          :width="col.width">
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="handleView(scope.row)">下载</el-button>
          </template>
        </el-table-column>

      </el-table>

    </el-main>
    <span slot="footer">
      <el-button type="primary" @click="fileDialg = false">关闭</el-button>
    </span>
  </el-dialog>

  <!-- 终止项目 dialog -->
    <el-dialog :visible.sync="terminateDialog" width="600px" center>
      <template #title>
        <h1 class="mid-title">终止项目</h1>
      </template>
      <el-main>
        <el-row>
          <el-col :span="4" :offset="2" class="text-right">
             <label class="desc">项目编号：</label>
          </el-col>
          <el-col :span="15" :offset="1">
            <label class="bw">{{tempProject.projectNo}}</label>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="4" :offset="2" class="text-right">
             <label class="desc">项目名称：</label>
          </el-col>
          <el-col :span="15" :offset="1">
            <label class="bw">{{tempProject.projectName}}</label>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="4" :offset="2" class="text-right">
             <label class="desc">终止原因：</label>
          </el-col>
          <el-col :span="15" :offset="1">
            <el-input type="textarea" v-model="terminationReason" :autosize="{minRows: 4}"  placeholder="请输入原因（必填）"></el-input>
          </el-col>
        </el-row>
      </el-main>
      <span slot="footer">
        <el-button @click="terminateDialog = false">取消</el-button>
        <el-button type="primary" @click="handleDoTerminate">确定终止</el-button>
      </span>
    </el-dialog>
  </el-container>
</template>

<script>
import { httpGet,httpPost } from '@/utils/http'
import global from '@/common/global'
export default {
  name: 'ConfirmResult',
  data() {
    return {
      project:{},
      items:[],
      supplierList: [],
      // 确认成交
      confirmDialog: false,
      resultReason:'',
      tempSupplier:{},
      // 终止项目
      terminateDialog: false,
      terminationReason: '',
      tempProject:{projectName:'临时项目名称',projectNo:'B2020082100001'},

      urls: {
        getResult: '/ewbid/bidding/tbEwProjectSupplierOfferSum/getProjectConfirm',
        updateResult: '/ewbid/bidd/bnProjectController/confirmProjectResult',
        getFiles:'/ewbid/bidd/bnProjectController/selectAttachmentsAndDiskInfos'
      },
      fileDialg: false,
      tempFiles: [],
      fileColumns: [
        {id:1,prop:'name',width:'250px',label:'附件名称'},
        {id:1,prop:'suffix',width:'70px',label:'格式'},
        {id:1,prop:'netWork',width:'250px',label:'网盘链接'},
        {id:1,prop:'netCode',width:'80px',label:'提取码'},
        {id:1,prop:'createTime',width:'175px',label:'上传时间'},
      ],
      budgetTotal: '',//项目总预算
      currentOrg:{
        id:''
      },
      /** 优惠率项目： 当前的供应商报价明细下标 **/
      rateProjectItemIndex:0

    }
  },

  beforeMount(){
    this.currentOrg.id = this.$ls.get("storeInfo").id
    this.project = this.$ls.get('confirmProject')
    this.getProjectItemResult()
  },

  computed: {
    totalItemBugdet(){
      if(this.items.length == 1)
        return ''
      let ttt =  this.items.reduce( (preV, item) =>
        preV + Number(item.itemBudget)
      ,0)
      return ttt.toFixed(2)
    }
  },

  methods: {

    /**
     * @Description: 获取项目报价结果
     * @Author: Niklaus
     * @Date: 2021-08-29 02:20:30
     */
    getProjectItemResult(){

      httpGet(global.gateway + this.urls.getResult,{projectId: this.project.id}).then(res => {
        if(res.data.success) {
          this.items =  res.data.result.items
          let resList = res.data.result.list;
          this.budgetTotal = res.data.result.budgetTotal;
          if(resList&&resList.length>0&&this.currentOrg.id){
            resList.forEach(element => {
              if(element.supplierStoreId==this.currentOrg.id){
                this.supplierList.push(element);
              }
            });
          }else{
            this.supplierList = resList;
          }
        }else {
          this.$message.error('获取数据失败')
        }
      }).catch( error => console.log(error) )
    },

    handleComfirm(){
      let data = {
        id: this.project.id,
        supplierStoreId : this.tempSupplier.supplierStoreId,
        remark: this.resultReason
      }
      httpPost(global.gateway + this.urls.updateResult, data).then(res =>{
        if(res.data.success) {
          this.getProjectItemResult()
          this.$message({
            type:'success',
            message: '确定成交结果成功'
          })
          this.project.processDetailStatus = '21';
          this.confirmDialog = false
        }else{
          this.$message({
              type:'error',
              message: '确定成交结果失败，请稍后再试！'
            })
        }
      }).catch( error => console.log(error) )
    },

    /**
     * @Description: 合并行
     * @Author: Niklaus
     * @Date: 2021-08-28 15:12:14
     */
    objectSpanMethod({row, columnIndex, rowIndex}) {
      let ttt = 0
      for (let i = 0; i < this.items.length; i++) {
        if(this.items[i].id == row.itemId) {
          ttt = this.items[i].itemList.length
          break
        }
      }
      if (columnIndex <= 4){
        if(rowIndex % ttt === 0)
          return { rowspan: ttt,  colspan: 1}
        else
         return {rowspan: 0,colspan: 0}
      }
    },

    showFile(row){
      console.log(JSON.stringify(row));
      this.tempSupplier = row
      this.fileDialg  = true
      let params={
        projectId: row.projectId,
        supplierStoreId: row.supplierStoreId
      }
      httpGet(global.gateway +this.urls.getFiles,params).then(res=>{
        if(res.data.success){
          this.tempFiles = res.data.result;
        }else{
          this.$message({
              type:'error',
              message: '查询附件失败，请稍后再试！'
            })
        }
      }).catch( error => console.log(error) )
    },

    /**
     * @Description: 打开确认成交 dialog
     * @Author: Niklaus
     * @Date: 2021-08-28 16:25:44
     * @param {*} row
     */
    openConfirmDialog(row,index) {
      if(this.project.quotationMethod== '4') {
        for (let i = 0; i < this.supplierList.length; i++) {
          const supplier = this.supplierList[i];
          if(supplier.confirmStatus == '00') {
            this.$message.error('存在“未确认”状态的供应商,不允许成交')
            return
          }
        }
      }
      this.tempSupplier =row
      this.rateProjectItemIndex = index
      this.confirmDialog = true
    },


    handleTerminate(){
      this.terminateDialog = true
    },

    handleDoTerminate(){
      this.terminateDialog = false
    },
    //查看链接
    handleView(row){
      if(row.type=='1'&&row.url){
        window.open(row.url);
      }else if(row.type=='2'&&row.netWork){
        window.open(row.netWork);
      }else{
        this.$message({
            type:'error',
            message: '未查询到附件链接！'
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.el-container {
  padding-bottom: 100px;
  position: relative;
  .el-row {
    .desc,.bw {
      line-height: 35px;
    }
  }
}
.el-header {
  h1 {
    font-size: 23px;
    font-weight: bold;
    line-height: 35px;
  }
}

.el-table::before {
  background-color: unset;
}

</style>
